define("ember-gestures/services/-gestures", ["exports", "@ember/application", "@ember/service", "rsvp", "ember-gestures/utils/string/dasherized-to-camel", "ember-gestures/utils/string/capitalize-word"], function (_exports, _application, _service, _rsvp, _dasherizedToCamel, _capitalizeWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const {
    Promise,
    // jshint ignore:line
    defer // jshint ignore:line
  } = _rsvp.default;
  class Gestures extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_recognizers", {});
    }
    get _fastboot() {
      let owner = (0, _application.getOwner)(this);
      return owner.lookup("service:fastboot");
    }
    get isFastBoot() {
      return this._fastboot && this._fastboot.isFastBoot;
    }
    retrieve(names) {
      let promises = names.map(name => {
        return this.lookupRecognizer(name);
      });
      return _rsvp.default.all(promises);
    }
    createRecognizer(name, details) {
      const eventName = (0, _dasherizedToCamel.default)(details.eventName || name).toLowerCase();
      const gesture = (0, _capitalizeWord.default)(details.recognizer);
      const options = details.options || {};
      options.event = eventName;
      options.name = name;
      const Recognizer = new Hammer[gesture](options);
      Recognizer.initialize = defer();
      this._recognizers[name] = Recognizer;
      return Recognizer;
    }
    setupRecognizer(name, details) {
      if (this.isFastBoot) {
        return;
      }
      return Promise.resolve(this.createRecognizer(name, details))

      // includes
      .then(Recognizer => {
        if (details.include) {
          const included = details.include.map(name => {
            return this.__speedyLookupRecognizer(name);
          });
          return _rsvp.default.all(included).then(recognizers => {
            Recognizer.recognizeWith(recognizers);
            return Recognizer;
          });
        }
        return Recognizer;
      })

      // excludes
      .then(Recognizer => {
        if (details.exclude) {
          const excluded = details.exclude.map(name => {
            return this.__speedyLookupRecognizer(name);
          });
          return _rsvp.default.all(excluded).then(recognizers => {
            Recognizer.requireFailure(recognizers);
            Recognizer.exclude = recognizers;
            Recognizer.initialize.resolve(Recognizer);
            return Recognizer;
          });
        } else {
          Recognizer.exclude = [];
          Recognizer.initialize.resolve(Recognizer);
          return Recognizer;
        }
      });
    }
    __speedyLookupRecognizer(name) {
      let recognizer = this._recognizers[name];
      if (recognizer) {
        return recognizer;
      }
      const path = `ember-gesture:recognizers/${name}`;
      const details = (0, _application.getOwner)(this).factoryFor(path);
      if (details) {
        return this.setupRecognizer(name, details.class);
      }
      return Promise.reject(new Error(`ember-gestures/recognizers/${name} was not found. You can scaffold this recognizer with 'ember g recognizer ${name}'`));
    }
    lookupRecognizer(name) {
      let recognizer = this._recognizers[name];
      if (recognizer) {
        return recognizer.initialize.promise.then(function (recognizer) {
          return recognizer;
        });
      }
      const path = `ember-gesture:recognizers/${name}`;
      const details = (0, _application.getOwner)(this).factoryFor(path);
      if (details) {
        return this.setupRecognizer(name, details.class);
      }
      return Promise.reject(new Error(`ember-gestures/recognizers/${name} was not found. You can scaffold this recognizer with 'ember g recognizer ${name}'`));
    }
  }
  _exports.default = Gestures;
});