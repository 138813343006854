define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faFolderOpen = {
    prefix: 'far',
    iconName: 'folder-open',
    icon: [576, 512, [128194, 128449, 61717], "f07c", "M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"]
  };
  var faTennisBall = {
    prefix: 'far',
    iconName: 'tennis-ball',
    icon: [512, 512, [127934], "f45e", "M248 463.8c0-55.2 21.1-110.4 63.3-152.6s97.4-63.2 152.6-63.3C459.7 139.5 372.5 52.3 264 48.2c0 55.2-21.1 110.4-63.3 152.6S103.4 264 48.2 264C52.3 372.5 139.5 459.7 248 463.8zm48-3.7C378.8 444 444 378.8 460.2 296c-41.7 1-83.1 17.3-114.9 49.2S297 418.4 296 460.2zM51.9 216c41.7-1 83.1-17.3 114.9-49.2S215 93.6 216 51.9C133.2 68 68 133.2 51.9 216zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
  };

  exports.faFolderOpen = faFolderOpen;
  exports.faTennisBall = faTennisBall;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
