define('@fortawesome/pro-solid-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faGolfFlagHole = {
    prefix: 'fas',
    iconName: 'golf-flag-hole',
    icon: [512, 512, [], "e3ac", "M162.7 2.5c-7.4-3.7-16.3-3.3-23.4 1.1S128 15.7 128 24V232c0 1.3 .1 2.7 .3 4c-.2 1.3-.3 2.6-.3 4v92.8C51.5 349.4 0 380.5 0 416c0 53 114.6 96 256 96s256-43 256-96s-114.6-96-256-96c-27.9 0-54.8 1.7-80 4.8V246.8l194.7-97.4c8.1-4.1 13.3-12.4 13.3-21.5s-5.1-17.4-13.3-21.5l-208-104zM288 416c0 17.7-28.7 32-64 32s-64-14.3-64-32s28.7-32 64-32s64 14.3 64 32z"]
  };
  var faSpa = {
    prefix: 'fas',
    iconName: 'spa',
    icon: [576, 512, [], "f5bb", "M183.1 235.3c33.7 20.7 62.9 48.1 85.8 80.5c7 9.9 13.4 20.3 19.1 31c5.7-10.8 12.1-21.1 19.1-31c22.9-32.4 52.1-59.8 85.8-80.5C437.6 207.8 490.1 192 546 192h9.9c11.1 0 20.1 9 20.1 20.1C576 360.1 456.1 480 308.1 480H288 267.9C119.9 480 0 360.1 0 212.1C0 201 9 192 20.1 192H30c55.9 0 108.4 15.8 153.1 43.3zM301.5 37.6c15.7 16.9 61.1 71.8 84.4 164.6c-38 21.6-71.4 50.8-97.9 85.6c-26.5-34.8-59.9-63.9-97.9-85.6c23.2-92.8 68.6-147.7 84.4-164.6C278 33.9 282.9 32 288 32s10 1.9 13.5 5.6z"]
  };
  var faPool8Ball = {
    prefix: 'fas',
    iconName: 'pool-8-ball',
    icon: [512, 512, [], "e3c5", "M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm384-64a128 128 0 1 0 -256 0 128 128 0 1 0 256 0zm-80-48c0 11.5-4 22-10.7 30.2C304.8 184.5 312 199.4 312 216c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-16.6 7.2-31.5 18.7-41.8C212 166 208 155.5 208 144c0-26.5 21.5-48 48-48s48 21.5 48 48zm-48 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32zm24 56a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"]
  };
  var faHeat = {
    prefix: 'fas',
    iconName: 'heat',
    icon: [448, 512, [], "e00c", "M176 32c17.7 0 32 14.3 32 32v80c0 34.6 11.2 68.3 32 96l19.2 25.6C288.3 304.4 304 351.5 304 400v48c0 17.7-14.3 32-32 32s-32-14.3-32-32V400c0-34.6-11.2-68.3-32-96l-19.2-25.6C159.7 239.6 144 192.5 144 144V64c0-17.7 14.3-32 32-32zM32 96c17.7 0 32 14.3 32 32v32c0 34.6 11.2 68.3 32 96l19.2 25.6C144.3 320.4 160 367.5 160 416v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V416c0-34.6-11.2-68.3-32-96L44.8 294.4C15.7 255.6 0 208.5 0 160V128c0-17.7 14.3-32 32-32zm320 32v32c0 34.6 11.2 68.3 32 96l19.2 25.6C432.3 320.4 448 367.5 448 416v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V416c0-34.6-11.2-68.3-32-96l-19.2-25.6C303.7 255.6 288 208.5 288 160V128c0-17.7 14.3-32 32-32s32 14.3 32 32z"]
  };
  var faFishingRod = {
    prefix: 'fas',
    iconName: 'fishing-rod',
    icon: [448, 512, [], "e3a8", "M295.9 48c-3 0-6 .4-8.8 1.2L209.8 71.3C137.7 91.9 88 157.8 88 232.8V288h8c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V320c0-17.7 14.3-32 32-32h8V232.8c0-96.4 63.9-181.2 156.7-207.7L273.8 3.1C281 1 288.4 0 295.9 0C340.1 0 376 35.9 376 80.1V136c0 13.3-10.7 24-24 24s-24-10.7-24-24V80.1C328 62.4 313.6 48 295.9 48zM256 344c0-71.6 55.6-127.8 89-148.1c4.3-2.6 9.6-2.6 14 0c33.5 20.3 89 76.6 89 148.1c0 32-16 80-64 112l27.3 27.3c3 3 4.7 7.1 4.7 11.3V496c0 8.8-7.2 16-16 16H304c-8.8 0-16-7.2-16-16v-1.4c0-4.2 1.7-8.3 4.7-11.3L320 456c-48-32-64-80-64-112zm128-32a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"]
  };
  var faGrid2 = {
    prefix: 'fas',
    iconName: 'grid-2',
    icon: [512, 512, [], "e196", "M224 80c0-26.5-21.5-48-48-48H80C53.5 32 32 53.5 32 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM288 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48zM480 336c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z"]
  };

  exports.faFishingRod = faFishingRod;
  exports.faGolfFlagHole = faGolfFlagHole;
  exports.faGrid2 = faGrid2;
  exports.faHeat = faHeat;
  exports.faPool8Ball = faPool8Ball;
  exports.faSpa = faSpa;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
