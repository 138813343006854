define("ember-gestures/components/fast-action", ["exports", "@ember/component", "ember-gestures/templates/components/fast-action", "@ember/string"], function (_exports, _component, _fastAction, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _fastAction.default,
    tagName: 'button',
    attributeBindings: ['style', 'type'],
    style: (0, _string.htmlSafe)('touch-action: manipulation; -ms-touch-action: manipulation;'),
    type: 'button',
    text: '',
    action: '',
    context: '',
    click: function () {
      this.sendAction('action', this.get('context')); // eslint-disable-line
    }
  });
});