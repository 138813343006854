define("ember-place-autocomplete/services/google-place-autocomplete/manager", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Manager extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "numberOfActiveAutoCompleteFields", 0);
    }
    /**
     * @description Increments the counter of active components.
     * Intended to be used everytime a new place-autocomplete-filed is
     * instantiated.
     */
    register() {
      this.numberOfActiveAutoCompleteFields += 1;
    }

    /**
     * @description Decrements the counter of active components.
     * Intended to be used everytime a new place-autocomplete-filed is
     * going to be destroyed.
     */
    unregister() {
      this.numberOfActiveAutoCompleteFields -= 1;
    }

    /**
     * @description Cleanup DOM when ALL component instances of place-autocomplete-field
     * are removed from the DOM. If there are still components active, it does nothing.
     *
     * @return boolean - Indicates whether the DOM was cleaned or not.
     */
    removePlacesAutoCompleteContainersIfRequired() {
      if (!document || this.numberOfActiveAutoCompleteFields > 0) {
        return false;
      }
      const pacContainers = document.querySelectorAll('.pac-container');
      for (let index = 0; pacContainers.length > index; index++) {
        pacContainers[index].parentNode.removeChild(pacContainers[index]);
      }
      return true;
    }
  }
  _exports.default = Manager;
});