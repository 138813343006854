define("ember-power-select-with-create/components/power-select-multiple-with-create", ["exports", "ember-power-select-with-create/components/power-select-with-create", "@ember/object", "@embroider/util", "ember-power-select/components/power-select-multiple"], function (_exports, _powerSelectWithCreate, _object, _util, _powerSelectMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PowerSelectMultipleWithCreate = _exports.default = (_class = class PowerSelectMultipleWithCreate extends _powerSelectWithCreate.default {
    get powerSelectComponent() {
      return (0, _util.ensureSafeComponent)(this.args.powerSelectComponent || _powerSelectMultiple.default, this);
    }
    selectOrCreate(selection, select) {
      let suggestion = selection.filter(option => {
        return option.__isSuggestion__;
      })[0];
      if (suggestion) {
        this.args.onCreate(suggestion.__value__, select);
      } else {
        this.args.onChange(selection, select);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "selectOrCreate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectOrCreate"), _class.prototype), _class);
});