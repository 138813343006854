define("ember-place-autocomplete/services/google-place-autocomplete", ["exports", "@ember/service", "rsvp", "@ember/utils"], function (_exports, _service, _rsvp, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GooglePlaceAutocomplete extends _service.default {
    constructor() {
      super(...arguments);
      const google = this.google || (window ? window.google : null);
      if (google && document) {
        this.google = google;
        const googlePlaces = this.google.maps.places;
        this.autocompleteService = new googlePlaces.AutocompleteService();
        this.placesService = new googlePlaces.PlacesService(document.createElement('div'));
        this.sessionToken = new googlePlaces.AutocompleteSessionToken();
      }
    }
    getPlacePredictions(properties) {
      if (!properties.hasOwnProperty('input')) {
        return _rsvp.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }
      if ((0, _utils.isBlank)(properties.input)) {
        return _rsvp.Promise.resolve([]);
      }
      properties.sessionToken = this.sessionToken;
      return new _rsvp.Promise(resolve => {
        this.autocompleteService.getPlacePredictions(properties, this._googleResponseCallback.bind(this, [resolve], []));
      });
    }
    getQueryPredictions(properties) {
      if (!properties.hasOwnProperty('input')) {
        return _rsvp.Promise.reject(new Error('[service/google-place-autocomplete] input property was not passed inside properties object param'));
      }
      if ((0, _utils.isBlank)(properties.input)) {
        return _rsvp.Promise.resolve([]);
      }
      return new _rsvp.Promise(resolve => {
        this.autocompleteService.getQueryPredictions(properties, this._googleResponseCallback.bind(this, [resolve], []));
      });
    }
    getDetails(request) {
      request.sessionToken = this.sessionToken;
      if (!request.hasOwnProperty('fields') && !request.hasOwnProperty('placeId')) {
        return _rsvp.Promise.reject(new Error('[service/google-place-autocomplete] getDetails needs the placeId and fields as properties of the request params'));
      }
      this.updateSessionToken();
      return new _rsvp.Promise(resolve => {
        this.placesService.getDetails(request, this._googleResponseCallback.bind(this, [resolve], {}));
      });
    }
    _googleResponseCallback(promiseCallbacks, failResponseReturnValue, requestResult, status) {
      const google = this.google || (window ? window.google : null);
      const [resolve] = promiseCallbacks;
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        return resolve(requestResult);
      }
      return resolve(failResponseReturnValue);
    }
    updateSessionToken() {
      const googlePlaces = this.google.maps.places;
      this.sessionToken = new googlePlaces.AutocompleteSessionToken();
    }
  }
  _exports.default = GooglePlaceAutocomplete;
});